import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = ({ location }) => (
    <Layout location={location}>
        <Seo
            title="404 | MediCareMaster"
            description="If You Are Over 64 Then You Qualify For Medicare. We Help You Find The Right Coverage For You In Your Area From Trusted Providers."
            image="http://medicaremaster.org/src/images/slide03.jpg"
        />
        <div className="not-found">
            <h1>404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
    </Layout>
);

export default NotFoundPage;
